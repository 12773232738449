import axios from "@axios";
import { $themeConfig } from "@themeConfig";
////////////////////////////////////////////
const moduleName = "dailybook";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchModuleAccountPlans(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchModule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName +`/${id}`)
          .then((response) => {
            // boolean conversions
            const data_to_update = response.data;
            resolve(data_to_update);
          })
          .catch((error) => reject(error));
      });
    },
    fetchModuleRequirement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName +
              `/${id}`
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    addModule(ctx, moduleData) {
      return new Promise((resolve, reject) => {
        myApi
          .post(moduleName, {
            moduleName: moduleData,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteModule(ctx, id) {
      return new Promise((resolve, reject) => {
        myApi
          .delete(moduleName + '/' + id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    suspendModule(ctx, id) {
      return new Promise((resolve, reject) => {
        myApi
        .put(moduleName + '/' + id + "/suspend")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    unsuspendModule(ctx, id) {
      return new Promise((resolve, reject) => {
        myApi
        .put(moduleName + '/' + id + "/activate")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
